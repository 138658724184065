import 'devextreme/dist/css/dx.common.css';

import pMinDelay from 'p-min-delay';
import React from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import { Loadable } from './components/loadable';
import LoaderApp from './components/loader-app';
// import Content from './Content';
import { AuthProvider, useAuth } from './contexts/auth';
import { NavigationProvider } from './contexts/navigation';
import './dx-styles.scss';
import './themes/generated/theme.additional.css';
import './themes/generated/theme.base.css';
import UnauthenticatedContent from './UnauthenticatedContent';
import { useScreenSizeClass } from './utils/media-query';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}



const pMinDelayTime = 250;
const Content = Loadable(() => pMinDelay(import('./Content'), pMinDelayTime), { fallback: <LoaderApp /> });

function App() {
    const { user, loading, service } = useAuth();
    // const { navigationData: { currentPath } } = useNavigation();
    // console.log(currentPath)
    const query = useQuery();
    const redirect_url = query.get("service");


    if (loading || (service != null && service !== "")) {
        return (
            <LoaderApp />
        );
    }

    if (user) {
        if (redirect_url == null) {
            return <Content />;
        } else {
            return <UnauthenticatedContent />;
        }
        //

    }

    return <UnauthenticatedContent />;
}

export default function () {
    const screenSizeClass = useScreenSizeClass();

    return (
        <Router>
            <AuthProvider>
                <NavigationProvider>
                    <div className={`portalApp app ${screenSizeClass}`}>
                        <App />
                    </div>
                </NavigationProvider>
            </AuthProvider>
        </Router>
    );
}
