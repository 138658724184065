import Button from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import { Link } from 'react-router-dom';
import UserPanel from '../user-panel/user-panel';
import './header.scss';

const renderLogo = () => {
  return <Link to={"/home"}>
    <img src="../images/logo_header.png" className="header-logo" style={{ height: 40, marginLeft: 40 }}></img>
  </Link>;
}

export default ({ menuToggleEnabled, title, toggleMenu }) => {

  return (
    <header className={'header-component'}>

      <Toolbar className={'header-toolbar brand-header'}>

        <Item location={'before'}
          render={renderLogo}>
        </Item>
        <Item
          location={'before'}
          cssClass={'header-title'}
        // text="SMART SCHOOL SOLUTION"

        >
          {/* <a className="header-title">SMART SCHOOL SOLUTION</a> */}
        </Item>
        <Item
          location={'after'}
          locateInMenu={'auto'}
          
        >
          <Dropdown
            // placeholder='Ngôn ngữ/ Language'
            // fluid
            selection
          
            onChange={(e,{value})=>{
              localStorage.language= value;
              window.location.reload()
            }}
            defaultValue={localStorage.language != null ? localStorage.language : "vi"}
            options={[
              { key: "vi", value: "vi", text: "Việt Nam", flag: "vn" },
              { key: "en", value: "en", text: "English", flag: "us" }
            ]}
          />
        </Item>
        <Item
          location={'after'}
          locateInMenu={'auto'}
          menuItemTemplate={'userPanelTemplate'}
        >
          <Button
            className={'user-button authorization'}
            width={300}
            height={'100%'}
            stylingMode={'text'}
          >
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>
        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={'list'} />
        </Template>
      </Toolbar>
    </header>);
};

